
.content {
  padding-bottom: 20px;
  .title {
    margin-bottom: 15px;
  }
  .date {
    font-size: 12px;
    color: $text-light-gray;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #E1E1E1;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
