@import './colors.scss';
@import './fonts.scss';

.panel {
  opacity: 0.98;
  position: fixed;
  top: 0;
  left: -500px;
  z-index: 2;
  background-color: $pure-white;
  height: 100%;
  width: 500px;
  transition: transform 0.6s cubic-bezier(.21,.39,.2,.96);
  @import './loader.scss';
  &.show {
    transform: translateX(500px);
  }
  .close-icon-wrapper {
    width: 100%;
    height: 50px;
    text-align: right;
  }
  .contents-wrapper {
    height: 90%;
    overflow-y: auto;
    display: none;
    &.loaded {
      display: block;
    }
    &.works {
      @import './works.scss';
    }
    @import './contact.scss';
    @import './credits.scss';
  }
  .navigation {
    &-footer {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
    }
    &-icon {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      svg, span {
        pointer-events: none;
        color: $text-gray;
      }
    }
  }
  .content {
    margin: 20px 30px 30px 30px;
    .heading {
      margin-bottom: 10px;
    }
    .image-wrapper {
      text-align: center;
      margin-bottom: 30px;
      &.works {
        margin-bottom: 10px;
      }
      img {
        width: 100%;
        border-radius: 35px;
      }
    }
    .links-wrapper {
      text-align: center;
      margin: 15px 0;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .panel {
    left: 0;
    top: 100vh;
    width: 100%;
    &.show {
      transform: translateY(-100vh);
    }
  }
}
