@import './colors.scss';

.hamburger {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  background-color: $icon-background;
  border: 0;
  margin: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.8s;
  overflow: visible;
}
  .hamburger:hover {
    cursor: pointer;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: $navbar-icon;
  }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: flex;
  justify-content: center;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 27px;
    height: 2px;
    background-color: $navbar-icon;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }
    
    .hamburger--collapse .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
      .hamburger--collapse .hamburger-inner::after {
        top: -20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
      .hamburger--collapse .hamburger-inner::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    
    .hamburger--collapse.is-active .hamburger-inner {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
      .hamburger--collapse.is-active .hamburger-inner::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
      .hamburger--collapse.is-active .hamburger-inner::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }
    
    /*
       * Collapse Reverse
       */
    .hamburger--collapse-r .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
      .hamburger--collapse-r .hamburger-inner::after {
        top: -20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
      .hamburger--collapse-r .hamburger-inner::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    
    .hamburger--collapse-r.is-active .hamburger-inner {
      transform: translate3d(0, -10px, 0) rotate(45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
      .hamburger--collapse-r.is-active .hamburger-inner::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
      .hamburger--collapse-r.is-active .hamburger-inner::before {
        top: 0;
        transform: rotate(90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.menu {
  opacity: 0.98;
  height: 100%;
  width: 500px;
  position: absolute;
  right: -500px;
  background-color: $pure-white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.8s cubic-bezier(.21,.39,.2,.96);
  &.show {
    transform: translateX(-500px);
  }
  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 500px;
    li {
      font-family: 'Avenir Medium';
      list-style: none;
      color: $text-gray;
      font-size: 25px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .menu {
    width: 100vw;
    right: -100vw;
    &.show {
      transform: translateX(-100vw);
    }
  }
}
