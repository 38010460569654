@import './navbar.scss';
@import './point.scss';
@import './panel.scss';
@import './text.scss';
@import './icons.scss';

* {
  margin: 0;
  padding: 0;
}

html, body {
  overflow: hidden;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
    &.loaded {
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform 0.5s ease-in-out;
    }
}
