.socials {
  display: flex;
  width: 100%;
  margin: 50px auto;
  justify-content: space-around;
  width: 55%;
  a {
    border: 1px solid $text-light-gray;
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
  }
}
